@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Croissant+One:regular);

body {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  background: #020010;
  font-family: "Croissant One", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

body,
html {
  scroll-behavior: smooth;
}
